import { ProjectSettings } from '@valiot/settings'
import packageJson from '../package.json'
const settings: ProjectSettings = {
  version: packageJson.version,
  copilotEnabled: true,
  title: 'Cloudflare test updated 99',
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
    routerCode: import.meta.env.VITE_ROUTER_CODE,
  },
  customerLogo: {
    src: <div>{'<cust. logo here>'}</div>,
  },
  analytics: {
    enabled: true,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'auto',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: true,
  },
  sidebarDefault: 'expanded',
  home: 'Test',
  routes: [
    {
      path: 'test',
      text: 'test',
      iconSource: 'fas',
      icon: 'fire',
      component: 'Test',
      sidebarDefault: 'expanded',
    },
    {
      path: 'new-screen',
      text: 'New Screen',
      iconSource: 'fas',
      icon: 'water-rise',
      component: 'Test',
    },
  ],
  dropdowns: [],
  sections: [
    {
      path: 'admin',
      title: 'Administración',
      directory: 'admin',
      routes: [],
      dropdowns: [
        {
          text: 'permisos',
          directory: 'permissions',
          path: 'permissions',
          icon: 'key',
          iconSource: 'fas',
          routes: [
            {
              path: 'users',
              text: 'Users',
              iconSource: 'fas',
              icon: 'user',
              component: 'Users',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'groups',
              text: 'Groups',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'tenancy-admin',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'TenancyAdmin',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
      ],
    },
  ],
}

export default settings
